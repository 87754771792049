.input {
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  outline: none;
  border-width: 0;
  font-size: 1rem;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.input::placeholder {
  color: #ffffffcc;
}

.darkText {
  color: var(--brand-text-dark)
}
