.container {
  width: 100%;
  box-shadow: 0 10px 15px 0px rgba(0, 0, 0, 0.2);
  background-color: #e5e5e5;
}

.container.invalidUrl {
  background-color: #aaa;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 20%;
  background-image: url(../../../assets/graphics/error.svg);
}

.frame {
  position: relative;
  border: 0;
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9999;
  }

  .fullScreen::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.8);
  }
}
