/* Global style sheet containing all native styling. */

/* Custom CSS properties */
:root {
  --brand-knltb-blue: #3eaeae;
  --brand-purple: #185d5d;
  --brand-blue-darker: #469090;
  --brand-blue-dark: #235e5e;
  --brand-blue-normal: rgb(36, 148, 186);
  --brand-blue-light: #61d4d4;
  --brand-blue-lighter: #7bdfdf;
  --brand-text-dark: #1a1239;
  --brand-text-secondary: #1a123977;
  --brand-text-light: #fff;

  --brand-orange: #e45318;
  --brand-green: #149140;
  --brand-red: #a70012;

  --brand-light-blue: #add8e6;
  --brand-yellow: #ffff00;
  --brand-dark-blue: #00008b;

  --content-side-padding: 0 15px;
}

/* Global styles for native elements */

* {
  box-sizing: border-box;
  /* All elements have a padding and margin of zero by default are should remain
  so. Elements are spaced by Spacing elements and pushed to the inside by
  container elements. */
  margin: 0;
  padding: 0;
  /* The following properties are used to indicate unstyled elements. */
  font-family: 'Touche', sans-serif;

  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 14px;
  /* Some browsers implement text size inflation such as mobile Safari in 
  landscape. Setting this property to none disabled this behaviour. */
  -webkit-text-size-adjust: none;
}

body {
  background-color: var(--brand-knltb-blue);
  color: #fff;
}

h1 {
  font-size: 1.8rem;
  font-weight: normal;
}

h2 {
  font-size: 1.4rem;
  font-weight: normal;
}

strong {
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

ul {
  padding-inline-start: 40px;
}

a {
  color: var(--brand-knltb-blue);
  text-decoration: none;
  font-size: 0.9em;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th {
  text-align: left;
  padding: 10px 5px;
  border-bottom: 2px solid #0000000c;
}

table td {
  padding: 15px 5px;
  border-bottom: 1px solid #0000000c;
}
