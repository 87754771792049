.phaseColorItem {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  opacity: 0.1;
  border: 2px solid #7bc7bf;
}

.selected {
  opacity: 1;
}
