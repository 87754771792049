.button {
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  outline: none;
  border-width: 0;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
}
.button::after {
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: transparent;
  position: absolute;
  border-radius: 8px;
}

.button:disabled::after {
  background-color: #ffffff99;
}

.small-size {
  padding: 15px;
}

.white-flavour {
  background-color: #fff;
  color: var(--brand-knltb-blue);
}
.white-flavour svg {
  fill: #000;
}

.ghost-flavour {
  background-color: transparent;
  border: 1px solid var(--brand-blue-light);
  color: var(--brand-knltb-blue);
}

.blue-flavour {
  background-color: var(--brand-blue-light);
  color: #fff;
}
.blue-flavour svg {
  fill: #fff;
}

.text-flavour {
  background-color: transparent;
  color: inherit;
  border-width: 0;
}
.blue-flavour svg {
  fill: inherit;
}


.orange-flavour {
  background-color: var(--brand-orange);
  color: #fff;
}
.orange-flavour svg {
  fill: #fff;
}

.danger-flavour {
  background-color: var(--brand-red);
  color: #fff;
}
.danger-flavour svg {
  fill: #fff;
}

.dark-blue-flavour {
  background-color: var(--brand-blue-darker);
  color: #fff;
}

.fitContent {
  width: fit-content;
}
