.level {
  width: 60px;
  height: 60px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
}

.achieved {
  background-size: cover;
  box-shadow: none;
}

.treated {
  opacity: 0.54;
}
