.name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 20%;
  padding-right: 26%;
  text-align: right;
  transform-origin: bottom left;
  transform: rotate(15deg);
  pointer-events: none;
  text-shadow: 0px 0px 5px black;

  /* HACK -- Quick fix to prevent the text from overlaying the levels. This 
  assumes the swirl's width is always equal to some factor of the screen's width
  which is not true. */
  font-size: min(4vw, 1rem);
}

.identifier {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 83%;
  padding-right: 5%;
  text-align: center;
  transform-origin: bottom left;
  transform: rotate(15deg);
  pointer-events: none;
  text-shadow: 0px 0px 5px black;
}

.levels {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform-origin: top left;
  transform: rotate(-13deg);
}

.treated {
  opacity: 0.54;
}

.levels > .level {
  position: absolute;
  width: 10%;
  aspect-ratio: 1;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
}

/* Each of the level selectors have a given relative offset to the right. */
.levels > .level:nth-child(1) {
  right: 10%;
}

/* Each of the level selectors have a given relative offset to the right. */
.levels > .level:nth-child(2) {
  right: 30%;
}

/* Each of the level selectors have a given relative offset to the right. */
.levels > .level:nth-child(3) {
  right: 51%;
}

.levels > .level.achieved {
  /* When the level is achieved, it gets the tennis ball sprite. */
  background-size: 100%;
  box-shadow: none;
}
