.backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 6;
}

.scrollContainer {
  overflow-y: auto;
  min-height: 100vh;
  width: 100%;
  padding: 50px 15px;
}

.modal {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  color: #000;
}
