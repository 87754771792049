.button {
  position: absolute;
  right: 10px;
  bottom: 12px;
  border-radius: 8px;
  padding: 15px 14px 13px 17px;
}

.disabled {
  opacity: 0.2;
  backdrop-filter: blur(2px);
}
