.select {
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  outline: none;
  border-width: 0;
  font-size: 1rem;
  background: rgba(0,0,0,.15);
  color: #fff;
  position: relative;
  cursor: pointer;
  min-width: 0;
}

.darkText {
  color: var(--brand-text-dark);
}

.deselector {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.dropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  border-radius: 0 0 8px 8px;
  z-index: 5;
  margin-top: -1px;
  max-height: 300px;
  overflow-y: auto;
}

.option {
  padding: 15px 20px;
}

.selectedOption {
  background: var(--brand-blue-dark);
}

.opened.select {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 0 0;
  color:#fff
}

.opened.select,
.opened .dropdown {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  background: var(--brand-blue-light);
}
.opened .dropdownArrow {
  transform: rotate(180deg);
}

.selectionCounter {
  opacity: 0.78;
}

.valueContainer {
  position: relative;
  padding-right: 25px;
}
.multipleSelected .valueContainer {
  padding-right: 50px;
}

.metadataContainer {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.requiredInput {
  opacity: 0;
  top:0;
  height: 1px;
  width: 1px;
  touch-action: none;
  position: absolute;
}