.flex {
  position: relative;
}

.flex::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--brand-knltb-blue);

  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  opacity: 1;
  visibility: visible;
  transition: 0.2s opacity;
  animation-name: fadeIn;
  z-index: 4;
}

.hasLoaded::after {
  animation-name: fadeOut;
  visibility: hidden;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    visibility: visible;
  }
  100% {
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}
